import { NavLink, useHistory } from 'react-router-dom';
import Button from './Button';
import { MdFileDownload as File } from 'react-icons/md';
import styles from './Documents.module.scss';

export default () => {
    return (
        <>
            <article className={`${styles.article} content`}>
                <h2>
                    وثائق المشاركين
                </h2>
                <p>يرجى من السيدات والسادة المشاركين في مبادرة فُسحة إبداع تحميل الملفات التالية:</p>

                <h3>
                    لقاء المشاركين
                    <span>23/أيار/2021</span>
                </h3>
                <ul>
                    <li><a href="/files/برنامج اللقاء.pdf" download><File />برنامج اللقاء</a></li>
                    <li><a href="/files/العرض التاريخي وقيم المكان.pdf" download><File />العرض التاريخي وقيم المكان</a></li>
                    <li><a href="/files/دراسة شاملة لساحة باب توما.pdf" download><File />دراسة شاملة لساحة باب توما</a></li>
                </ul>

                <h3>
                    المرحلة الأولى
                    <span>27/نيسان/2021</span>
                </h3>
                <ul>
                    <li><a href="/files/الرؤية.pdf" download><File />الرؤية</a></li>
                    <li><a href="/files/المحددات الأثرية.pdf" download><File />المحدّدات الأثرية</a></li>
                    <li><a href="/files/مراحل التقديم.pdf" download><File />مراحل التقديم</a></li>
                    <li><a href="/files/المخطط الكادسترائي.pdf" download><File />المخطط الكادسترائي</a></li>
                    <li><a href="/files/مخطط الاستعمالات.pdf" download><File />مخطط الاستعمالات</a></li>
                    <li><a href="/files/مخطط الحالة الفيزيائية.pdf" download><File />مخطط الحالة الفيزيائية</a></li>
                    <li><a href="/files/مخطط الارتفاعات.pdf" download><File />مخطط الارتفاعات</a></li>
                    <li><a href="/files/مخطط مواد البناء.pdf" download><File />مخطط مواد البناء</a></li>
                </ul>

                {/*
                <div className={styles.info}>
                    <p>
                        تبدأ المرحلة الأولى في <b>27/نيسان/2021</b>. وسيتم التواصل مع المشاركين خلال ذلك لترتيب عقد لقاء يتم خلاله إجراء عروض توضيحية من قبل اللجنة المنظمة بالإضافة إلى تحديد الموعد النهائي لتسليم المشروع الأولي.
                    </p>
                    <p>
                        لأي استفسار يمكنكم الاتصال بفريق المبادرة على البريد الالكتروني: <a href={`mailto:babtouma${String.fromCharCode(64)}lovedamascus${String.fromCharCode(46)}com`}>{`babtouma${String.fromCharCode(64)}lovedamascus${String.fromCharCode(46)}com`}</a> أو على رقم الموبايل <a href="tel:00963933338063">0933338063</a>.
                    </p>
                </div>
                */}
            </article>
        </>
    );
}