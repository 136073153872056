import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { checkSVG } from '../js/browser';
// import { APIUrl } from '../js/settings';
import { getCurrentYear } from '../js/dates';
// import LoaderImage from './LoaderImage';
import Menu from './Menu';
import Logo from './Logo';
import styles from './Template.module.scss';

// https://github.com/react-ga/react-ga
// import ReactGA from 'react-ga';

export default (props) => {
    const [fixedNav, setFixedNav] = useState(false);

    useEffect(() => {
        checkSVG();
    });

    const renderPage = () => {
        return (
            <>
                <header className={styles.header}>
                    <NavLink
                        to="/"
                    >
                        <Logo className={`${styles.logo} ${fixedNav ? styles.fixed : ""}`} />
                    </NavLink>
                    <NavLink
                        to="/"
                    >
                        <h1>
                            مبادرة فُسحة إبداع<br />
                            <span>رؤية متكاملة لساحة باب توما</span>
                        </h1>
                    </NavLink>
                </header>
                <nav
                    className={`${styles.nav} ${fixedNav ? styles.fixed : ""}`}
                >
                    <Menu
                        type="responsive"
                    />
                </nav>
                <main className={styles.main}>
                    {props.children}
                </main>
                <footer className={styles.footer}>
                    <div>
                        <small>
                            © جميع الحقوق محفوظة، مبادرة فُسحة إبداع {getCurrentYear()}.
                        </small>
                        <Menu
                            type="simple"
                        />
                    </div>
                </footer>
            </>
        );
    };

    return (
        renderPage()
    );
}