import { NavLink, useHistory } from 'react-router-dom';
import VideoLoader from './VideoLoader';
import Button from './Button';
import { MdCreate as Register, MdDescription as Conditions } from 'react-icons/md';
import styles from './Home.module.scss';

export default () => {
    const history = useHistory();

    // src="https://www.youtube.com/embed/27yegiTyl0Q?autoplay=1&amp;controls=0&amp;loop=1&amp;playlist=27yegiTyl0Q&amp;showinfo=0&amp;modestbranding=1"

    return (
        <>
            <VideoLoader
                src="https://www.youtube.com/embed/27yegiTyl0Q?autoplay=0&amp;controls=0&amp;loop=0&amp;showinfo=0&amp;modestbranding=1"
            />

            <article className={`${styles.article} content`}>
                <h2>
                    مبادرة فُسحة إبداع<br />
                    <span>رؤية متكاملة لساحة باب توما </span>
                </h2>

                <p>
                    تأكيداً على الهوية العمرانية والمعمارية لساحة باب توما باعتبارها أحد أهم الفراغات العمرانية في دمشق القديمة، تعلن محافظة دمشق ودائرة آثار دمشق بالتعاون مع كليتي الهندسة المعمارية والفنون الجميلة بجامعة دمشق والجمعية البريطانية السورية عن مشروع تصميم حضري لفراغ ساحة باب توما بحيث يكون:
                </p>
                <ol>
                    <li>فراغاً يوثق العلاقة بين داخل المدينة القديمة وخارجها.</li>
                    <li>فراغاً تفاعلياً يعزز ارتباط القاطن والشاغل والزائر بالمكان ويكون محفزاً لحواسهم. </li>
                    <li>فراغاً يحقق العدالة المكانية ويعزز إحساس الانتماء لمستخدميه.</li>
                    <li>فراغاً ينظر الى التراث بكافة أشكاله المادي واللامادي بحس تطويري.</li>
                    <li>فراغاً يستخدم الفن بكافة أشكاله كوسيلة إبداعية لتقديم تجارب حضرية مختلفة ويهتم بالاحتياجات الترفيهية والرياضية والثقافية والسياحية.</li>
                </ol>
            </article>
        </>
    );
}