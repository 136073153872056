import { NavLink, useHistory } from 'react-router-dom';
import Button from './Button';
import VideoLoader from './VideoLoader';
import { MdCreate as Register, MdDescription as Conditions } from 'react-icons/md';
import Attachments from './Attachments';
import styles from './Photos.module.scss';

export default () => {
    const history = useHistory();

    const photos1 = [
        { type: 1, isRemote: false, link: "/photos/press-conference/01.jpg", order: 1, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/press-conference/02.jpg", order: 2, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/press-conference/03.jpg", order: 3, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/press-conference/04.jpg", order: 4, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/press-conference/05.jpg", order: 5, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/press-conference/06.jpg", order: 6, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/press-conference/07.jpg", order: 7, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/press-conference/08.jpg", order: 8, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/press-conference/09.jpg", order: 9, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/press-conference/10.jpg", order: 10, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/press-conference/11.jpg", order: 11, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/press-conference/12.jpg", order: 12, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/press-conference/13.jpg", order: 13, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/press-conference/14.jpg", order: 14, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/press-conference/15.jpg", order: 15, title: "", featured: false }
    ];

    const photos2 = [
        { type: 1, isRemote: false, link: "/photos/first-meeting/01.jpg", order: 1, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/02.jpg", order: 2, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/03.jpg", order: 3, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/04.jpg", order: 4, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/05.jpg", order: 5, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/06.jpg", order: 6, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/07.jpg", order: 7, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/08.jpg", order: 8, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/09.jpg", order: 9, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/10.jpg", order: 10, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/11.jpg", order: 11, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/12.jpg", order: 12, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/13.jpg", order: 13, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/14.jpg", order: 14, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/15.jpg", order: 15, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/16.jpg", order: 16, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/17.jpg", order: 17, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/18.jpg", order: 18, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/19.jpg", order: 19, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/20.jpg", order: 20, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/21.jpg", order: 21, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/22.jpg", order: 22, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/23.jpg", order: 23, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/24.jpg", order: 24, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/25.jpg", order: 25, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/26.jpg", order: 26, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/27.jpg", order: 27, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/28.jpg", order: 28, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/29.jpg", order: 29, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/first-meeting/30.jpg", order: 30, title: "", featured: false }
    ];

    const photos3 = [
        { type: 1, isRemote: false, link: "/photos/location-tour/01.jpg", order: 1, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/location-tour/02.jpg", order: 2, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/location-tour/03.jpg", order: 3, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/location-tour/04.jpg", order: 4, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/location-tour/05.jpg", order: 5, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/location-tour/06.jpg", order: 6, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/location-tour/07.jpg", order: 7, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/location-tour/08.jpg", order: 8, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/location-tour/09.jpg", order: 9, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/location-tour/10.jpg", order: 10, title: "", featured: false },
        { type: 1, isRemote: false, link: "/photos/location-tour/11.jpg", order: 11, title: "", featured: false }
    ];


    return (
        <>
            <article className={`${styles.article} content`}>
                <h2>
                    معرض الصور
                </h2>

                <h3>
                    الزيارة الميدانية للفرق المشاركة إلى ساحة باب توما
                    <span>6/حزيران/2021</span>
                </h3>
                <Attachments
                    attachments={photos3}
                />

                <h3>
                    لقاء المشاركين في مبادرة فُسحة إبداع
                    <span>23/أيار/2021</span>
                </h3>
                <Attachments
                    attachments={photos2}
                />

                <h3>
                    المؤتمر الصحفي الخاص بإطلاق مبادرة فُسحة إبداع
                    <span>14/آذار/2021</span>
                </h3>
                <Attachments
                    attachments={photos1}
                />

            </article>
        </>
    );
}