import React, { useEffect, useState } from 'react';
import styles from './Menu.module.scss';
import { isMenuItemFromType } from '../js/settings';
import { NavLink } from 'react-router-dom';
import { MdExpandMore as Open, MdMenu as MenuToggle, MdClose as Close, MdAssignment as Survey } from 'react-icons/md';


export default (props) => {
    const menus = [
        { id: "link1", title: "عن المبادرة", link: "/" },
        { id: "link2", title: "شروط التقديم", link: "/conditions" },
        { id: "link3", title: "وثائق المشاركين", link: "/documents" },
        { id: "link4", title: "صور", link: "/photos" },
        { id: "link5", title: "فيديو", link: "/videos" }
        // { id: "link6", title: "الاستبيان", link: "/questionnaire", icon: <Survey /> }
    ];

    const [menuOpened, setMenuOpened] = useState(false);

    const toggleMainMenu = () => {
        setMenuOpened(!menuOpened);
    };

    return (
        <div className={`${props.type ? styles[props.type] : styles.simple} ${props.className ? props.className : ""}`}>
            {
                props.type === "responsive"
                &&
                <button
                    className={`${styles.toggle} ${menuOpened ? styles.close : ""}`}
                    onClick={toggleMainMenu}
                >
                    {!menuOpened ? <MenuToggle /> : <Close />}
                </button>
            }

            <ul className={`${styles.root} ${menuOpened ? styles.show : ""}`}>
                {
                    menus.map(item =>
                        <li key={item.id}>
                            <NavLink
                                to={item.link}
                                onClick={toggleMainMenu}
                                className={styles.link}
                                activeClassName={styles.active}
                                exact={true}
                            >
                                {/*
                                    item.icon && props.type != "simple" && item.icon
                                */}
                                {item.title}
                            </NavLink>
                        </li>
                    )
                }
            </ul>
        </div>
    );
}