import React, { useEffect } from 'react';
import Error from './components/Error';
import Template from './components/Template';
import Home from './components/Home';
import Conditions from './components/Conditions';
import Documents from './components/Documents';
import Videos from './components/Videos';
import Photos from './components/Photos';

// https://reactrouter.com/web/guides/scroll-restoration
import ScrollToTop from './components/ScrollToTop';

// https://reacttraining.com/blog/react-router-v5-1/
import { BrowserRouter, Route, Switch } from 'react-router-dom';

const App = () => {
    // remove loader when everything is ready
    useEffect(() => {
        const loader = document.getElementById('loader');
        loader && loader.parentElement.removeChild(loader);
    });

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Error>
                <Template>
                    <Switch>
                        <Route path='/' exact>
                            <Home />
                        </Route>
                        <Route path='/documents' exact>
                            <Documents />
                        </Route>
                        <Route path='/phase1' exact>
                            <Documents />
                        </Route>
                        <Route path='/conditions' exact>
                            <Conditions />
                        </Route>
                        <Route path='/videos' exact>
                            <Videos />
                        </Route>
                        <Route path='/photos' exact>
                            <Photos />
                        </Route>
                        <Route path='*'>
                            <Home />
                        </Route>
                    </Switch>
                </Template>
            </Error>
        </BrowserRouter>
    );
}

export default App;
