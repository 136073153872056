import { NavLink, useHistory } from 'react-router-dom';
import Button from './Button';
import VideoLoader from './VideoLoader';
import { MdCreate as Register, MdDescription as Conditions } from 'react-icons/md';
import styles from './Videos.module.scss';

export default () => {
  const history = useHistory();

  return (
    <>
      <article className={`${styles.article} content`}>
        <h2>
          مقاطع فيديو
        </h2>

        <h3>
          ملخص مبادرة فُسحة إبداع
        </h3>
        <VideoLoader
          className={styles.video}
          src="https://www.youtube.com/embed/nrRSidtuqZ4?autoplay=0&amp;controls=1&amp;loop=0&amp;showinfo=0&amp;modestbranding=1"
          normal
        />

        <h3>
          الزيارة الميدانية للفرق المشاركة إلى ساحة باب توما
          <span>6/حزيران/2021</span>
        </h3>
        <VideoLoader
          className={styles.video}
          src="https://www.youtube.com/embed/9K_FulWgXf4?autoplay=0&amp;controls=1&amp;loop=0&amp;showinfo=0&amp;modestbranding=1"
          normal
        />

        <h3>
          لقاء المشاركين في مبادرة فُسحة إبداع
          <span>23/أيار/2021</span>
        </h3>
        <VideoLoader
          className={styles.video}
          src="https://www.youtube.com/embed/VLzXEMG6-6g?autoplay=0&amp;controls=1&amp;loop=0&amp;showinfo=0&amp;modestbranding=1"
          normal
        />

        <h3>
          المؤتمر الصحفي الخاص بإطلاق مبادرة فُسحة إبداع
          <span>14/آذار/2021</span>
        </h3>
        <VideoLoader
          className={styles.video}
          src="https://www.youtube.com/embed/RT69a3X5fOs?autoplay=0&amp;controls=1&amp;loop=0&amp;showinfo=0&amp;modestbranding=1"
          normal
        />
      </article>
    </>
  );
}