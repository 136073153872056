import React from 'react';
import styles from './VideoLoader.module.scss';

export default (props) => {
    return (
        <div className={`${styles.container} ${props.className ? props.className : ""} ${props.normal ? styles.normal : ""}`}>
            <div className={styles.video}>
                <iframe
                    title={props.title}
                    src={props.src}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    autoPlay
                >
                </iframe>
            </div>
        </div>
    );
}