import { NavLink, useHistory } from 'react-router-dom';
import Button from './Button';
import { MdCreate as Register, MdDescription as Conditions } from 'react-icons/md';
import styles from './Conditions.module.scss';

export default () => {
    const history = useHistory();

    return (
        <>
            <article className={`${styles.article} content`}>
                <h2>
                    شروط التقديم
                </h2>

                <ol>
                    <li>يشترط التقديم من خلال فريق عمل مؤلف من أربعة أفراد لا يتجاوز أي منهم سنَّ الثانية والثلاثين.</li>
                    <li>يتوجب أن يكون أحد أعضاء الفريق من اختصاص الهندسة المعمارية (خريج أو طالب تخرج أو ماجستير) والثاني من اختصاص الفنون الجميلة (خريج أو طالب تخرج أو ماجستير)، أما باقي الاعضاء فيمكن أن يكونوا من أي اختصاص يرونه مناسبا.</li>
                    <li>يبدأ التقديم بتاريخ 28/شباط/2021 وآخر موعد للتقديم هو 1/نيسان/2021.</li>
                </ol>

                <div className={styles.info}>
                    <h3>
                        معلومات أخرى
                    </h3>
                    <ul>
                        <li>سوف يتم موافاة الفرق المشاركة بالشروط الفنية للتصاميم المطلوبة فور قبولها.</li>
                        <li>يمنح التصميم الفائز مبلغ <strong>عشرة ملايين ليرة سورية</strong>.</li>
                        <li>يمنح ثاني أفضل تصميم مبلغ <strong>ستة ملايين ليرة سورية</strong>.</li>
                        <li>يمنح ثالث أفضل تصميم مبلغ <strong>أربعة ملايين ليرة سورية</strong>.</li>
                        <li>سوف يتم توثيق المبادرة عبر برنامج تلفزيوني. </li>
                        <li>لأي استفسار يمكنكم الاتصال بفريق المبادرة على البريد الالكتروني: <a href={`mailto:babtouma${String.fromCharCode(64)}lovedamascus${String.fromCharCode(46)}com`}>{`babtouma${String.fromCharCode(64)}lovedamascus${String.fromCharCode(46)}com`}</a> أو على رقم الموبايل <a href="tel:00963933338063">0933338063</a>.</li>
                    </ul>
                </div>
            </article>
        </>
    );
}