import React, { Component } from 'react';
import { MdErrorOutline as ErrorIcon } from 'react-icons/md';
import Logo from './Logo';
import styles from './Error.module.scss';

export default class Error extends Component {

    // https://reactjs.org/docs/error-boundaries.html
    // in development, we need to close the error overlay
    // in order to see the error component

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className={styles.container}>
                    <div className={styles.error}>
                        <h1>
                            <ErrorIcon />
                            حصل خطأ ما، يرجى إعادة تحميل الصفحة.
                        </h1>
                        <p>
                            يمكن أن يكون ذلك بسبب خطأ في الاتصال بشبكة الانترنت أو بسبب خطأ تقني مؤقت في الموقع.
                        </p>
                        <Logo className={styles.logo} />
                    </div>
                </div>
            )
        }

        return this.props.children;
    }
}