import React, { useState, useEffect, useRef } from 'react';
import { RootUrl } from '../js/settings';
import ImageLoader from './ImageLoader';
import VideoLoader from './VideoLoader';
import styles from './Attachments.module.scss';
import { MdPlayCircleOutline as Play, MdPauseCircleOutline as Pause, MdAttachFile as AttachmentFile, MdKeyboardArrowRight as Previous, MdKeyboardArrowLeft as Next } from 'react-icons/md';

export default (props) => {
    // state from parent
    const attachments = props.attachments;

    const [photos, setPhotos] = useState([]);
    const [currentPhoto, setCurrentPhoto] = useState(0);

    const [videos, setVideos] = useState([]);
    const [currentVideo, setCurrentVideo] = useState(0);

    const [files, setFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);

    const [autoNavigation, setAutoNavigation] = useState(false);

    // swipe effects for the images
    //const gallery = useRef(null);
    //const swipe = (() => {
    //    let touchstartX;
    //    let touchstartY;
    //    let touchendX;
    //    let touchendY;

    //    const handleGesture = (event) => {
    //        if (touchendX < touchstartX) {
    //            // console.log('Swiped left');

    //            // if a swipe occured, then we need to stop mouse click events
    //            event.preventDefault();
    //            navigate("photos", "next");
    //        }

    //        if (touchendX > touchstartX) {
    //            // console.log('Swiped right');

    //            // if a swipe occured, then we need to stop mouse click events
    //            event.preventDefault();
    //            navigate("photos", "previous");
    //        }

    //        if (touchendY < touchstartY) {
    //            // console.log('Swiped up');
    //        }

    //        if (touchendY > touchstartY) {
    //            // console.log('Swiped down');
    //        }

    //        if (touchendY === touchstartY) {
    //            // console.log('Tap');
    //            // navigate("photos", "next");
    //        }
    //    }

    //    return (event) => {
    //        let type = event.type;

    //        if (type === "touchstart") {
    //            // event.preventDefault();
    //            touchstartX = event.changedTouches[0].screenX;
    //            touchstartY = event.changedTouches[0].screenY;
    //            handleGesture(event);
    //        }
    //        else if (type === "touchend") {
    //            // event.preventDefault();
    //            touchendX = event.changedTouches[0].screenX;
    //            touchendY = event.changedTouches[0].screenY;
    //            handleGesture(event);
    //        }
    //    };
    //})();
    //useEffect(() => {
    //    let galleryBox = gallery.current;

    //    if (galleryBox != null) {
    //        galleryBox.addEventListener('touchstart', swipe, false); // using capture so stop propagation will work correctly
    //        galleryBox.addEventListener('touchend', swipe, false); // using capture so stop propagation will work correctly
    //    }

    //    return () => {
    //        if (galleryBox != null) {
    //            galleryBox.removeEventListener('touchstart', swipe, false);
    //            galleryBox.removeEventListener('touchend', swipe, false);
    //        }
    //    };
    //});

    // auto navigate the images gallery
    let autoNavigate = useRef();
    const stopAutoNagivate = () => {
        clearInterval(autoNavigate.current);
    }
    const imagesAutoNavigate = () => {
        navigate("photos", "next", true);
    };
    useEffect(() => {
        if (autoNavigation) {
            autoNavigate.current = setInterval(imagesAutoNavigate, 5000);
        }

        return () => {
            if (autoNavigation) {
                stopAutoNagivate();
            }
        }
    });

    // update local state when the array is changed
    useEffect(() => {
        let photos = attachments.filter(a => a.type === 1);
        setPhotos(photos);

        let videos = attachments.filter(a => a.type === 3);
        setVideos(videos);

        let files = attachments.filter(a => a.type === 2);
        setFiles(files);
    }, [attachments]);

    // gallery navigation
    const navigate = (element, direction, auto = false) => {
        if (!auto) {
            setAutoNavigation(false);
        }

        let array;
        let current;
        let currentUpdate;

        switch (element) {
            case "photos":
                array = photos;
                current = currentPhoto;
                currentUpdate = setCurrentPhoto;
                break;
            case "videos":
                array = videos;
                current = currentVideo;
                currentUpdate = setCurrentVideo;
                break;
            case "files":
                array = files;
                current = currentFile;
                currentUpdate = setCurrentFile;
                break;
            default:
                break;
        }

        let newCurrent;
        if (direction === "next") {
            newCurrent = current + 1;
            newCurrent > array.length - 1 ?
                currentUpdate(0) :
                currentUpdate(newCurrent);

        } else if (direction === "previous") {
            newCurrent = current - 1;
            newCurrent < 0 ?
                currentUpdate(array.length - 1) :
                currentUpdate(newCurrent);
        }
    };

    return (
        <>
            {
                photos.length > 0
                &&
                <section className={styles.section}>
                    {/*<h2>Photos <span className={styles.number}>{photos.length}</span></h2>*/}
                    <div
                        className={`${styles.navigator}`}
                        //ref={gallery}
                    >
                        {
                            photos.length > 1
                            &&
                            <button
                                className={`${styles.arrow} ${styles.previous}`}
                                onClickCapture={() => navigate("photos", "previous")}
                            //onTouchStartCapture={(e) => { e.stopPropagation() }}
                            //onTouchEndCapture={(e) => { e.stopPropagation() }}
                            >
                                <Previous />
                            </button>
                        }

                        <figure>
                            <ImageLoader
                                className={styles.slide}
                                src={photos[currentPhoto].isRemote ? photos[currentPhoto].link : RootUrl + photos[currentPhoto].link}
                                alt={photos[currentPhoto].title}
                                caption={photos[currentPhoto].title}
                                onClick={() => navigate("photos", "next")}
                            />
                            {
                                photos.length > 1
                                &&
                                <div className={styles.pager}>
                                    {
                                        photos.map((photo, index) =>
                                            <button
                                                key={`ph${index}`}
                                                className={`${currentPhoto === index ? styles.active : ""}`}
                                                onClickCapture={() => setCurrentPhoto(index)}
                                            //onTouchStartCapture={(e) => { e.stopPropagation() }}
                                            //onTouchEndCapture={(e) => { e.stopPropagation() }}
                                            >
                                            </button>
                                        )
                                    }
                                </div>
                            }

                            {
                                photos.length > 1
                                &&
                                (
                                    autoNavigation
                                        ?
                                        <Pause
                                            className={styles.play}
                                            onClickCapture={() => setAutoNavigation(false)}
                                        //onTouchStartCapture={(e) => { e.stopPropagation() }}
                                        //onTouchEndCapture={(e) => { e.stopPropagation() }}
                                        />
                                        :
                                        <Play
                                            className={styles.play}
                                            onClickCapture={() => setAutoNavigation(true)}
                                        //onTouchStartCapture={(e) => { e.stopPropagation() }}
                                        //onTouchEndCapture={(e) => { e.stopPropagation() }}
                                        />
                                )
                            }
                            <figcaption>
                                {photos[currentPhoto].title}
                            </figcaption>
                        </figure>
                        {
                            photos.length > 1
                            &&
                            <button
                                className={`${styles.arrow} ${styles.next}`}
                                onClickCapture={() => navigate("photos", "next")}
                            //onTouchStartCapture={(e) => { e.stopPropagation() }}
                            //onTouchEndCapture={(e) => { e.stopPropagation() }}
                            >
                                <Next />
                            </button>
                        }
                    </div>
                </section>
            }
            {
                files.length > 0
                &&
                <section className={styles.section}>
                    <h2>Downloads <span className={styles.number}>{files.length}</span></h2>
                    <ul className={styles.files}>
                        {
                            files.map((file, index) =>
                                <li
                                    key={`file${index}`}
                                >
                                    <a
                                        href={file.isRemote ? file.link : RootUrl + file.link}
                                        title={file.title}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <AttachmentFile /> {file.title}
                                    </a>
                                </li>
                            )
                        }
                    </ul>
                </section>
            }
            {
                videos.length > 0
                &&
                <section className={styles.section}>
                    <h2>Videos <span className={styles.number}>{videos.length}</span></h2>
                    <div className={styles.navigator}>
                        {
                            videos.length > 1
                            &&
                            <button
                                className={`${styles.arrow} ${styles.previous}`}
                                onClick={() => navigate("videos", "previous")}
                            >
                                <Previous />
                            </button>
                        }
                        <figure>
                            <div className={styles.slide}>
                                <VideoLoader
                                    title={"Video" + videos.indexOf(videos[currentVideo])}
                                    src={videos[currentVideo].link}
                                />
                            </div>
                            {
                                videos.length > 1
                                &&
                                <div className={styles.pager}>
                                    {
                                        videos.map((video, index) =>
                                            <button
                                                key={`vd${index}`}
                                                className={`${currentVideo === index ? styles.active : ""}`}
                                                onClick={() => setCurrentVideo(index)}
                                            >
                                            </button>
                                        )
                                    }
                                </div>
                            }
                            <figcaption>
                                {videos[currentVideo].title}
                            </figcaption>
                        </figure>
                        {
                            videos.length > 1
                            &&
                            <button
                                className={`${styles.arrow} ${styles.next}`}
                                onClick={() => navigate("videos", "next")}
                            >
                                <Next />
                            </button>
                        }
                    </div>
                </section>
            }
        </>
    )
};