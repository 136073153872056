import React, { useState, useEffect } from 'react';
import styles from './ImageLoader.module.scss';

export default (props) => {
    const [loaded, setLoaded] = useState(false);
    const [src, setSrc] = useState("");
    const [alt, setAlt] = useState("");

    useEffect(() => {
        setLoaded(false);
        setSrc(props.src);
        setAlt(props.alt);
    }, [props.src, props.alt]);

    return (
        <div className={`${styles.container} ${props.className ? props.className : ""}`}>
            {
                !loaded
                &&
                <div className={styles.loader}>
                    {props.message}
                </div>
            }
            <img
                onLoad={() => setLoaded(true)}
                src={src}
                alt={alt}
                onClick={props.onClick}
            />
        </div>
    );
}